<template>
    <div class="h-screen dark:bg-gray-900 bg-white text-black dark:text-white flex justify-center flex-col">
        <div class="sm:px-5 xl:px-24  xl:container mx-auto sm:w-full md:w-1/2 lg:w-1/3 xl:w-1/3">
            <h1 class="text-4xl mb-5">Восстановление пароля</h1>
            <div class="dark:bg-gray-800 dark:shadow-none shadow-2xl rounded p-5">
                <small v-if="message.length" class="text-green-300">{{ message }}</small>
                <input placeholder="Email/Логин" v-model="email"
                       class="text-gray-700 placeholder-gray-600 w-full px-4 py-2.5 mt-2
                         transition duration-500 ease-in-out transform border-transparent
                         rounded bg-gray-200
                          dark:focus:bg-gray-200 outline-none
                         ">
                <small class="text-red-600"
                       v-if="!$v.email.required && $v.email.$dirty">{{ $t('forms.validation.required') }}</small>
                <small class="text-red-600"
                       v-if="!$v.email.email && $v.email.$dirty">{{ $t('forms.validation.email') }}</small>

                <div class="flex justify-center mt-2">
                    <button class="dark:bg-green-400 bg: px-10 py-2 rounded" @click.prevent="submit">Выслать код
                    </button>
                </div>


                <div v-if="loading" class="w-12 h-12 border-4 border-teal-600 rounded-full loader"></div>


            </div>
        </div>
        <div class="flex py-5 justify-center">
            <router-link tag="a" :to="{name:'login'}"
                         class="text-black dark:text-green-400  dark:hover:text-blue-300 hover:text-blue-400">Войти
            </router-link>
        </div>

    </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {required, email} from 'vuelidate/lib/validators'
import axios from "axios";

export default {
    meta: {
        title: 'Восстановление пароля'
    },
    name: "ResetPassword",
    data: () => ({
        loading: false,
        email: '',
        message: ''
    }),
    methods: {
        submit() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                axios.post('https://declarant.by/rest/user/password/reset', {email: this.email})
                    .then(res => {
                            this.message = res.data.data
                            this.$notify({
                                title: 'Успешно',
                                text: res.data.data,
                                type: 'success'
                            })
                        }
                    ).catch(err => {
                    this.message = err.response.data.message
                    this.$notify({
                        title: 'Ошибка',
                        text: err.response.data.message,
                        type: 'error'
                    })
                })
            }

        }
    },
    mixins: [validationMixin],
    validations: {
        email: {
            required, email
        }
    }
}
</script>

<style scoped>

</style>